<template>
  <v-app class>
    <div>
      <v-alert
        v-model="alert"
        border="left"
        dark
        dismissible
        class="alert"
        :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}"
      >
      {{alertMessage}}
      </v-alert>
    </div>

    <div class="row">
      <div class="col-md-6 ml-auto mr-auto">
        <v-container>
          <div class="mb-8 mt-8 text-center">
            <h1>Create your password </h1>
          </div>
          <v-form ref="contractEmployer" v-model="valid">
            <v-row no-gutters>
              <v-col cols="12">
                <TextField :disable="true" v-model="formData.username" :label="'Username'" />
              </v-col>
              <v-col cols="12">
                <TextField v-model="formData.firstName" :label="'First Name'" :type="'text'"  />
              </v-col>
              <v-col cols="12">
                <TextField v-model="formData.middleName" :label="'Middle Name'" />
              </v-col>
              <v-col cols="12">
                <TextField v-model="formData.lastName" :label="'Last Name'" :type="'text'"/>
              </v-col>
              <v-col cols="12">
                <TextField v-model="formData.password" :label="'New Password'" :type="'password'"/>
              </v-col>

              <v-col cols="12" class="d-flex justify-end">
                <div class="btn-group">
                  <Button
                    :label="'Submit'"
                    :btnType="'Submit'"
                    @onClick="onSave"
                    :disabled="!valid"
                    :isLoading="isLoading"
                    class="mr-4"
                  />
                  <Button :label="'Reset'" :btnType="'Cancel'" @onClick="onReset" />
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </div>
    </div>
  </v-app>
</template>
<script>

import TextField from '@/components/ui/form/TextField.vue'
import Button from '@/components/ui/form/Button.vue'
import { contractEmployeeruser, accountService } from '@/services'
import CryptoJS from 'crypto-js'

export default {
  components: {
    TextField,
    Button
  },
  data () {
    return {
      search: '',
      formData: {
        id: 0,
        firstName: '',
        lastName: '',
        middleName: '',
        username: '',
        password: '',
        ContractEmployerId: 0
      },
      valid: false,
      isLoading: false,
      isFetching: false,
      alertMessage: '',
      alert: false,
      alertType: ''
    }
  },
  computed: {},
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
    },
    onSave () {
      this.isLoading = true
      contractEmployeeruser.changePassword(this.formData)
        .then(() => {
          this.showAlertMessage(
            'Password successfully created',
            'success'
          )

          localStorage.removeItem('newuser')
          this.$router.go('/')
        })
        .catch((error) => {
          console.log(error)
          this.showAlertMessage('Contractor Rep creation failed', 'error')
        })
        .finally(() => {
          this.isLoading = false
          this.onReset()
        })
    },
    onReset () {
      this.$refs.contractEmployer.reset()
    }
  },
  mounted () {
    this.isFetching = true
    let username = CryptoJS.AES.decrypt(localStorage.getItem('newuser'), 'secret key')
    username = username.toString(CryptoJS.enc.Utf8)
    accountService.validateNewUser(username).then(res => {
      if (res.status === 200) {
        const data = res.data
        this.formData.id = data.id
        this.formData.username = data.username
        this.formData.lastName = data.lastName
        this.formData.firstName = data.firstName
        this.formData.middleName = data.middleName
        this.formData.ContractEmployerId = data.contractEmployerId
      }
      this.isFetching = false
    }).catch(() => {
      this.isFetching = false
    })
  }
}
</script>
<style scoped>
.search-btn {
  background-color: #e6e3e3;
  height: 40px;
  border-radius: 3px !important;
}
.btn-group{
  display: inline-flex;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
 .alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
</style>
